// Colors
$white: #fff;
$black: #000;
$gray: #464a4c;

$color_primary: #11cc9b;
$color_primary-dark: #08ad82;
$color_secondary: #64686e;
$color_accent: #ff724f;

$color_light: #f8f9fa;
$color_dark: #161719;

$color_body: $white;

// Fonts
$font_body: 'Cera Pro', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font_display: 'Bebas', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
