.benefits-section {
  $that: &;

  position: relative;
  padding-top: 54px;
  padding-bottom: 64px;
  overflow: hidden;

  @include laptop {
    padding-top: 142px;
    padding-bottom: 124px;
  }

  &__inner {
    @extend %container;
  }

  &__head {
  }

  &__title {
    text-align: center;
  }

  &__amount {
    display: flex;
    justify-content: center;
    margin-top: 24px;

    @include tablet {
      margin-top: 40px;
    }

    @include laptop {
      margin-top: 64px;
    }
  }

  &__wrap-amount {
    position: relative;
  }

  &__shadow-amount {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 232px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -116px;

    img {
      max-width: none;
    }
  }

  &__number-amount {
    color: $color_primary;
    font-family: $font_display;
    font-size: 92px;
    line-height: 1;
    text-transform: uppercase;

    @include tablet {
      font-size: 128px;
    }
  }

  &__caption-amount {
    position: absolute;
    bottom: 10px;
    left: 100%;
    color: $color_secondary;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 500;
    margin-left: 8px;
    white-space: nowrap;

    @include tablet {
      bottom: 16px;
    }
  }

  &__text {
    width: 60.2666667%;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      display: flex;
      justify-content: center;
      width: auto;
      font-size: 16px;
      line-height: 1.25;
    }
  }

  &__wrap-text {
    @include tablet {
      margin-top: 8px;
      padding-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.08);
    }
  }

  &__body {
    margin-top: 24px;

    @include tablet {
      margin-top: 80px;
    }
  }

  &__list {
    @include desktop {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 40px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    &:first-child {
      border-color: transparent;
    }

    @include tablet {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
    }

    @include desktop {
      margin: 0 auto;

      &:first-child,
      &:last-child {
        position: relative;
        top: -96px;
      }
    }
  }
}

.benefits-item {
  $that: &;

  position: relative;

  @include tablet {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 326px;
    height: 326px;
    background: -moz-linear-gradient(
      top,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-radius: 100%;
  }

  &__shadow {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    width: 94px;
    height: 143px;
    margin-left: -47px;
    //background: $color_primary;

    @include tablet {
      display: flex;
    }
  }

  &__img-shadow {
    max-width: none;
  }

  &__wrap {
    @include tablet {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 324px;
      height: 324px;
      background: $color_dark;
      border-radius: 100%;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__name {
    margin-top: 16px;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      max-height: 58px;
      margin-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 16px;
      overflow: hidden;
    }
  }

  &__text {
    max-width: 195px;
    margin: 8px auto 0 auto;
    color: $color_secondary;
    font-size: 14px;
    line-height: 1.714285714;
    font-weight: 500;
    text-align: center;

    span {
      color: $color_primary;
    }

    @include tablet {
      max-height: 96px;
      overflow: hidden;
    }
  }

  &__note {
    max-width: 217px;
    margin: 32px auto 0 auto;
    color: $color_secondary;
    font-size: 14px;
    line-height: 1.714285714;
    font-weight: 500;
    text-align: center;

    span {
      color: $color_primary;
    }

    @include tablet {
      display: none;
    }
  }

  &__outer-note {
    margin: 56px auto 0 auto;
    color: $color_secondary;
    font-size: 14px;
    line-height: 1.714285714;
    font-weight: 500;
    text-align: center;
    display: none;

    span {
      color: $color_primary;
    }

    @include tablet {
      display: block;
    }

    @include desktop {
      padding-bottom: 5px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.13);
    }
  }

  &__circle {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 382px;
    height: 382px;
    margin-top: -192px;
    margin-left: -192px;

    @include tablet {
      display: block;
    }
  }
}
