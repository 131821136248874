// CONTAINER
%container,
.container {
  width: 100%;
  max-width: 1601px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 30px;
  padding-right: 30px;

  @include phone-s {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include tablet {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@mixin out-gap {
  margin-left: -30px;
  margin-right: -30px;

  @include phone-s {
    margin-left: -20px;
    margin-right: -20px;
  }

  @include tablet {
    margin-left: -40px;
    margin-right: -40px;
  }
}
