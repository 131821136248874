.screen-about {
  $that: &;

  position: relative;
  z-index: 1;
  overflow: hidden;
  opacity: 0;

  .page_loaded & {
    opacity: 1;
  }

  &__picture {
    position: relative;
    height: 496px;

    @include tablet {
      height: 896px;
    }

    @include laptop {
      height: 1482px;
      height: calc(1482 / 1920 * 100vw);
    }
  }

  &__image {
    @include absolute-fit(cover);
    @include lazy-loaded;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100vw;
    height: 100vh;
    background: radial-gradient(
      ellipse at center,
      rgba(#161719, 0) 0%,
      rgba(#161719, 1) 30%
    );
    opacity: 0;

    @include laptop {
      opacity: 1;
    }
  }

  &__decor {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    margin-top: -64px;
    margin-left: 16px;

    @include tablet {
      //body
    }
  }

  &__mobile-decor {
    @include tablet {
      display: none;
    }
  }

  &__desktop-decor {
    display: none;

    @include tablet {
      display: block;
    }
  }
}
