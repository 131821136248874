.dealer-menu {
  $that: &;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  .dealer_open & {
    opacity: 1;
    pointer-events: all;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color_dark, 0.64);
    opacity: 0;

    .dealer_open & {
      opacity: 1;
      transition: opacity 0.6s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .dealer_closing & {
      opacity: 0;
      transition: opacity 0.6s 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-right: 32px;

    @include tablet {
      margin-top: 50px;
      margin-right: 60px;
    }
  }

  &__main {
    position: relative;
    flex: none;
    width: 100%;
    max-width: 567px;
    height: 100%;
    background-color: #fff;
    transform: translate3d(100%, 0, 0);
    will-change: transform;

    .dealer_open & {
      transform: translate3d(0, 0, 0);
      transition: transform 0.5s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .dealer_closing & {
      transform: translate3d(100%, 0, 0);
      transition: transform 0.5s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 80px 40px 40px;
    color: $color_dark;
    overflow-y: auto;

    @include tablet {
      padding: 80px;
    }
  }

  &__head {

    @include tablet {
      margin-top: 32px;
    }
  }

  &__title {
    font-family: $font_display;
    font-size: 34px;
    text-align: center;
  }

  &__subtitle {
    max-width: 371px;
    margin: 0 auto;
    padding: 8px;
    color: $color_primary-dark;
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
  }

  &__form {
    margin-top: 48px;

    @include tablet {
      margin-top: 64px;
    }
  }

  &__fields-form {
    max-width: 632px;
    margin: 0 auto;
  }

  &__field-form {
    margin-top: 32px;

    &:first-child {
      margin-top: 0;
    }

    @include tablet {
      margin-top: 48px;
    }
  }

  &__text-attach {
    color: #B5B5B9;
    font-size: 12px;
    line-height: 2;
    font-weight: 500;
    text-align: center;
  }

  &__field-attach {
    margin-top: 14px;
  }

  &__steps {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-left: -64px;
  }

  &__item-steps {
    position: relative;
    padding-left: 64px;
    color: #DEDEDF;
    font-size: 16px;
    line-height: 1.625;
    font-weight: 500;
    text-align: center;

    &._is-current {
      color: $color_primary-dark;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 9px;
      width: 45px;
      height: 1px;
      background: #DEDEDF;
    }

    &:first-child::before {
      display: none;
    }
  }

  &__step-1 {

    &._is-hidden {
      display: none;
    }
  }

  &__step-2 {

    &._is-hidden {
      display: none;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @include tablet {
      margin-top: 48px;
    }
  }
}
