.dealer-section {
	$that: &;

	padding-top: 48px;
	padding-bottom: 80px;
	overflow: hidden;

	@include tablet {
		padding-bottom: 111px;
	}

	@include laptop {
		padding-bottom: 190px;
	}

	&__container {
		@extend %container;
	}

	&__head {
		overflow: hidden;
	}

	&__title {
		position: relative;
		text-align: center;

		&::after {
			content: "";
			display: none;
			width: 16px;
			height: 3px;
			margin: 8px auto 0 auto;
			background-color: $color_primary;

			@include tablet {
				display: block;
			}
		}
	}

	&__body {
		position: relative;
		margin-top: 8px;
		margin-left: -30px;
		margin-right: -30px;

		@include phone-s {
			margin-left: -20px;
			margin-right: -20px;
		}

		@include tablet {
			margin-top: 48px;
			margin-left: -40px;
			margin-right: -40px;
		}

		@include laptop {
			display: flex;
			height: 680px;
			margin-top: 64px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	&__list {
		@include laptop {
			flex: none;
			width: 33.333333333%;
			border: 1px solid #242427;
		}
	}

	&__map {
		margin-top: 32px;
		padding-left: 30px;
		padding-right: 30px;

		@include phone-s {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include tablet {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include laptop {
			flex: 1 1 0%;
			margin-top: 0;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__top-angle {
		position: absolute;
		top: 0;
		left: 0;
		display: none;
		width: 100%;

		@include laptop {
			display: block;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: -16px;
			width: 16px;
			height: 16px;
			border-top: 1px solid rgba(255, 255, 255, 0.2);
		}

		&::before {
			left: -16px;
			border-left: 1px solid rgba(255, 255, 255, 0.2);
		}

		&::after {
			right: -16px;
			border-right: 1px solid rgba(255, 255, 255, 0.2);
		}
	}

	&__bot-angle {
		position: absolute;
		bottom: 0;
		left: 0;
		display: none;
		width: 100%;

		@include laptop {
			display: block;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			bottom: -16px;
			width: 16px;
			height: 16px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.2);
		}

		&::before {
			left: -16px;
			border-left: 1px solid rgba(255, 255, 255, 0.2);
		}

		&::after {
			right: -16px;
			border-right: 1px solid rgba(255, 255, 255, 0.2);
		}
	}

	&__circle {
		position: absolute;
		bottom: 0;
		right: 0;
		pointer-events: none;
		display: none;

		@include laptop {
			display: block;
		}
	}

	&__svg-circle {
		transform: translate3d(40%, 40%, 0);
	}
}
