.dealer-close {
  $that: &;

  position: relative;
  display: block;
  width: 29px;
  height: 34px;
  cursor: pointer;

  &::before {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 84px;
    height: 84px;
    margin-top: -42px;
    margin-left: -42px;
    background: rgba(#b4b7be, 0.13);
    border-radius: 100%;
    transform: scale(1.3);
    transform-origin: center;
    opacity: 0;
    transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    opacity 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    will-change: transform, opacity;

    @include laptop {
      display: block;
    }
  }

  &:hover {
    &::before {
      transform: scale(1);
      opacity: 1;
    }
  }

  &__lines {
  }

  &__line {
    position: absolute;
    top: 50%;
    left: 0;
    width: 29px;
    height: 2px;
    margin-top: -1px;
    border-radius: 2px;
    background: #b4b7be;
    opacity: 0;
    will-change: transform, opacity;

    #{$that}:hover & {
      background-color: $color_dark;
    }

    .dealer_open & {
      opacity: 1;

      &:first-child {
        transform: rotate(45deg);
        transition: transform 0.4s 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.4s 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0.4s 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:last-child {
        transform: rotate(-45deg);
        transition: transform 0.4s 0.75s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.55s 0.6s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0.4s 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }

    .dealer_closing & {
      opacity: 0;

      &:first-child {
        transform: rotate(0);
        transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      }

      &:last-child {
        transform: rotate(0);
        transition: transform 0.4s 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
        opacity 0.55s cubic-bezier(0.645, 0.045, 0.355, 1),
        background-color 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
  }
}
