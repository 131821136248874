.header {
	$that: &;

	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	padding: 12px 0 10px;
	opacity: 1;
	transition: opacity 0.6s 1.2s ease-in-out;
	border-bottom: 1px solid transparent;

	.page_scrolled & {
		background-color: rgba(#161719, 0.9);
		backdrop-filter: blur(5px);
		transition: border-color 0.25s ease;
		border-color: rgba(255, 255, 255, 0.08);
	}

	.section_light & {
		background-color: rgba(#fff, 0.9);
	}

	.section_light.page_scrolled & {
		border-color: rgba(0, 0, 0, 0.08);
	}

	@include laptop {
		padding-top: 1px;
		padding-bottom: 1px;
		opacity: 0;

		.page_detail & {
			transition: opacity 0.6s ease-in-out;
		}

		.page_loaded & {
			opacity: 1;
		}
	}

	&__inner {
		@extend %container;

		display: flex;
		flex-wrap: wrap;
		align-items: center;
		max-width: none;
	}

	&__logo {
		position: relative;
		margin-right: auto;

		img {
			max-width: 186px;
			max-height: 74px;
		}

		svg {
			max-width: 186px;
			max-height: 74px;
		}
	}

	&__link-logo {
		display: inline-block;
		vertical-align: top;

		@include laptop {
			&::before {
				content: "";
				position: absolute;
				top: -32px;
				right: -32px;
				bottom: -32px;
				left: -32px;
			}
		}
	}

	&__svg-logo {
		width: 125px;
		height: 51px;
		transition: color 0.25s ease, transform 0.25s ease;
		transform-origin: left center;
		transform: scale(1) translateY(10px);

		.page_scrolled & {
			transform: scale(0.744) translateY(0);
		}

		@include laptop {
			width: 186px;
			height: auto;
			transform: scale(1) translateY(32px);

			.page_scrolled & {
				transform: scale(0.602150538) translateY(0);
			}
		}

		.section_light & {
			color: $color_dark;
		}
	}

	&__img-logo {
		width: 125px;
		height: 51px;
		transition: color 0.25s ease, transform 0.25s ease;
		transform-origin: left center;
		transform: scale(1) translateY(10px);

		.page_scrolled & {
			transform: scale(0.744) translateY(0);
		}

		@include laptop {
			width: 186px;
			height: auto;
		}

		.section_light & {
			color: $color_dark;
		}
	}

	&__phone {
		display: none;
		margin-top: -6px;
		transform: translateY(20px);
		transition: transform 0.25s ease;

		.page_scrolled & {
			transform: translateY(0);
		}

		@include laptop {
			transform: translateY(40px);
			display: block;

			.page_scrolled & {
				transform: translateY(2px);
			}
		}
	}

	&__link-phone {
		color: $color_primary;
		font-size: 16px;
		line-height: 1.625;
		font-weight: 500;

		.section_light & {
			color: $color_dark;
		}

		&:hover {
			color: $color_accent;
		}
	}

	&__request {
		margin-left: 48px;
		transform: translateY(20px);
		transition: transform 0.25s ease;

		.page_scrolled & {
			transform: translateY(0);
		}

		@include phone-s {
			margin-left: 32px;
		}

		@include laptop {
			transform: translateY(40px);

			.page_scrolled & {
				transform: translateY(2px);
			}
		}
	}

	&__burger {
		flex: none;
		margin-left: 48px;
		transform: translateY(20px);
		transition: transform 0.25s ease;

		.page_scrolled & {
			transform: translateY(0);
		}

		.page_detail & {
			display: none;
		}

		@include laptop {
			margin-left: 96px;
			transform: translateY(40px);

			.page_scrolled & {
				transform: translateY(2px);
			}
		}
	}
}
