.menu-button {
	$that: &;

	position: relative;
	display: block;
	width: 29px;
	height: 34px;
	cursor: pointer;

	&::before {
		content: "";
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 84px;
		height: 84px;
		margin-top: -42px;
		margin-left: -42px;
		background: rgba($white, 0.13);
		border-radius: 100%;
		transform: scale(1.3);
		transform-origin: center;
		opacity: 0;
		transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55),
			opacity 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
		will-change: transform, opacity;

		@include laptop {
			display: block;

			.page_scrolled & {
				width: 64px;
				height: 64px;
				margin-top: -32px;
				margin-left: -32px;
			}
		}

		.section_light & {
			background: rgba(#b4b7be, 0.13);
		}
	}

	&:hover {
		&::before {
			transform: scale(1);
			opacity: 1;
		}
	}

	&__lines {
	}

	&__line {
		position: absolute;
		left: 0;
		width: 29px;
		height: 2px;
		border-radius: 2px;
		background: $white;
		will-change: transform, opacity;

		.section_light & {
			background: $color_dark;
		}

		&:first-child {
			top: 10px;
			transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1),
				opacity 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		&:last-child {
			bottom: 10px;
			transition: transform 0.4s 0.15s
					cubic-bezier(0.645, 0.045, 0.355, 1),
				opacity 0.55s cubic-bezier(0.645, 0.045, 0.355, 1);
		}

		.menu_open & {
			&:first-child {
				transform: translate3d(-24px, 0, 0);
				opacity: 0;
			}

			&:last-child {
				transform: translate3d(-24px, 0, 0);
				opacity: 0;
			}
		}
	}
}
