.energy {
  $that: &;

  position: relative;


  &__block {
    display: flex;
    justify-content: center;
  }

  &__svg-block {
    display: block;

    @include tablet {
      display: none;
    }
  }

  &__animate {
    display: none;
    width: 319px;
    height: 319px;
    margin: 0 auto;
    transform: translate3d(0, 0, 0);

    @include tablet {
      display: block;
    }

    [data-energy-path2],
    [data-energy-path3] {
      transform-origin: center;
    }

    [data-energy-path2] {
      transform: rotate(90deg);
    }

    [data-energy-path3] {
      transform: rotate(180deg);
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    justify-content: space-between;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    transform: translateX(-50%);

    @include tablet {
      display: flex;
    }
  }

  &__letter {
    color: $color-primary;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
}
