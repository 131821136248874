.form-checkbox {
  $that: &;

  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;

  &__input {
    @include visually-hidden;
  }

  &__box {
    position: relative;
    order: 1;
    display: flex;
    width: 28px;
    height: 28px;
    margin: 13px auto 0 auto;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    overflow: hidden;
    transition: border-color 0.6s 0.1s cubic-bezier(0.19, 1, 0.22, 1),
      background-color 0.6s 0.2s ease;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: $white;
      transition: transform 0.6s cubic-bezier(0.19, 1, 0.22, 1),
        background-color 0.6s 0.2s ease;
    }

    #{$that}__input:checked ~ & {
      background-color: #f5fcfa;
      border-color: $color_primary-dark;

      &::after {
        background: #f5fcfa;
        transform: translate3d(100%, 0, 0);
      }
    }
  }

  &__svg {
    display: block;
    margin: auto;
    color: $color_primary-dark;
  }

  &__text {
    color: $color_secondary;
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;

    a {
      text-decoration: underline;
      text-underline-offset: 2px;
      transition: color 0.25s ease;

      &:hover {
        color: $color_primary-dark;
      }
    }
  }
}
