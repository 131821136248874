.characteristics-price {
  $that: &;

  padding: 30px;
  border: 1px solid #2D2E30;

  @include tablet {
    padding: 48px;
  }

  @include  laptop {
    display: flex;
    flex-wrap: wrap;
    padding: 48px 0 54px;
  }

  &__item {
    position: relative;
    margin-top: 30px;
    padding-top: 30px;

    @include tablet {
      margin-top: 40px;
      padding-top: 40px;
    }

    @include laptop {
      flex: 1 1 0%;
      margin-top: 0;
      padding-top: 16px;
      padding-left: 48px;
      padding-right: 48px;
    }

    @include desktop {
      padding-left: 112px;
      padding-right: 112px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 108px;
      height: 1px;
      background-color: #232426;

      @include laptop {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      height: 108px;
      width: 1px;
      background-color: #232426;

      @include laptop {
        display: block;
      }
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;

      @include laptop {
        padding-top: 16px;
      }

      &::before {
        display: none;
      }
    }
  }

  &__name {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 500;
  }

  &__common {
    margin-top: 16px;

    @include tablet {
      display: flex;
      align-items: baseline;
      margin-top: 24px;
    }

    @include laptop {
      margin-top: 32px;
    }
  }

  &__current {
    font-family: $font_display;
    font-size: 46px;
    line-height: 1.260869565;
    font-weight: 700;

    span {
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__old {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
    font-family: $font_display;
    color: $color_secondary;
    font-size: 28px;
    line-height: 1.285714286;
    font-weight: 700;

    @include tablet {
      margin-top: 0;
      margin-left: 16px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 66px;
      height: 2px;
      margin-top: -1px;
      margin-left: -33px;
      background: $color_primary;
      transform-origin: center;
      transform: rotate(-35deg);
    }

    span {
      font-size: 10px;
      font-weight: 500;
    }
  }
}
