.app-sequence {
  $that: &;

  position: relative;
  display: none;
  width: 100%;
  height: 100vh;

  @include laptop {
    display: block;
  }

  canvas {
    display: block;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    @include object-fit(scale-down);
  }
}
