.detail-page {
	$that: &;

	padding-top: 128px;
	padding-bottom: 60px;

	@include tablet {
		padding-top: 160px;
		padding-bottom: 115px;
	}

	@include laptop {
		padding-top: 246px;
		padding-bottom: 126px;
	}

	&__container {
		@extend %container;
	}

	&__head {
	}

	&__title {
		text-align: center;
	}

	&__body {
		max-width: 1000px;
		margin-top: 48px;
		margin-left: auto;
		margin-right: auto;
		color: $color_secondary;
		font-size: 16px;
		line-height: 1.666666667;
		font-weight: 500;

		@include tablet {
			font-size: 18px;
		}

		@include laptop {
			margin-top: 64px;
		}

		p {
			margin-top: 24px;

			@include tablet {
				margin-top: 32px;
			}
		}

		> :first-child {
			margin-top: 0;
		}
	}

	&__back-home {
	}

	&__mobile-back-home {
		@include tablet {
			display: none;
		}
	}

	&__desktop-back-home {
		display: none;

		@include tablet {
			display: block;
		}
	}
}
