.extreme-section {
	$that: &;

	position: relative;
	background-color: $white;
	overflow: hidden;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color_dark;

		@include desktop {
			height: 89%;
		}
	}

	&__container {
		@extend %container;
	}

	&__back {
		position: absolute;
		top: 0;
		left: 50%;
		width: 100%;
		color: #1f2124;
		font-family: $font_display;
		font-size: 152px;
		line-height: 1;
		text-align: center;
		text-transform: uppercase;
		transform: translateX(-50%);
		display: flex;
		justify-content: center;
		white-space: nowrap;
		overflow: hidden;

		@include phone-s {
			font-size: calc(152 / 375 * 100vw);
		}

		@include tablet {
			font-size: calc(510 / 1024 * 100vw);
		}

		@include laptop {
			font-size: 510px;
		}
	}

	&__main {
		position: relative;
		padding-top: 64px;

		@include tablet {
			padding-top: calc(176 / 1024 * 100vw);
		}

		@include laptop {
			padding-top: 184px;
		}
	}

	&__head {
	}

	&__title {
		text-align: center;
	}

	&__subtitle {
		margin-top: 10px;
		color: $color_primary;
		font-size: 14px;
		line-height: 2;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;

		@include tablet {
			font-size: 16px;
		}
	}

	&__body {
		margin-top: 12px;
	}

	&__energy {
		margin-top: 16px;
		margin-bottom: -40px;

		@include tablet {
			margin-top: 128px;
			margin-bottom: -64px;
		}

		@include laptop {
			margin-top: 192px;
		}
	}

	&__picture {
		position: relative;
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
	}

	&__main-picture {
		position: relative;
		z-index: 1;
		display: flex;
		justify-content: center;
		height: 302px;

		@include tablet {
			height: 567px;
			transform: translateX(-24px);
		}
	}

	&__image-picture {
		@include absolute-fit(contain);
	}

	&__in-picture {
		position: absolute;
		top: 86px;
		right: 50%;
		height: 17px;
		transform: translateX(208px);

		@include tablet {
			top: 162px;
			height: 44px;
			transform: translateX(-120px);
		}
	}

	&__svg-in {
		height: 100%;
	}

	&__out-picture {
		position: absolute;
		top: 95px;
		left: 50%;
		height: 16px;
		transform: translateX(-166px);

		@include tablet {
			top: 183px;
			height: 36px;
			transform: translateX(127px);
		}
	}

	&__svg-out {
		height: 100%;
	}

	&__info {
		@extend %container;

		margin-top: 24px;
		padding-bottom: 64px;
		text-align: center;

		@include desktop {
			position: absolute;
			top: 0;
			left: 40px;
			width: 378px;
			padding-left: 0;
			padding-right: 0;
			margin-top: -32px;
			margin-right: 336px;
			text-align: right;
		}

		@include desktop-l {
			left: auto;
			right: 50%;
		}
	}

	&__name-info {
		color: $color_body;
		font-size: 20px;
		line-height: 1.6;
		font-weight: 500;
		text-transform: uppercase;

		@include tablet {
			font-size: 24px;
		}

		@include desktop {
			color: $color_body;
		}
	}

	&__text-info {
		margin-top: 12px;
		color: $color_secondary;
		font-size: 14px;
		line-height: 1.857142857;
		font-weight: 500;

		@include tablet {
			font-size: 16px;
		}
	}
}
