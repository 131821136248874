.whom-item {
  $that: &;

  &__shadow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    justify-content: center;
    align-items: center;

    @include laptop {
      display: flex;
    }
  }

  &__img-shadow {
    max-width: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__name {
    margin-top: 16px;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      margin-top: 24px;
      font-size: 16px;
    }
  }
}
