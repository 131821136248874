.layout {
  $that: &;

  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  height: auto;

  @include laptop {
    // scroll bar вешает overflow: hidden scroll
    // на секции service-section
    height: 100vh;
    overflow: hidden !important;
  }

  &__body {
    flex: 1 1 auto;
    display: flex;

    > :first-child {
      width: 100%;
    }
  }

  &__footer {
    position: relative;
    z-index: 80;
    bottom: 0;
    flex: none;
  }

  .scroll-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    will-change: transform;
  }

  .scrollbar-track {
    background-color: transparent;
  }

  .scrollbar-thumb {
    width: 5px;
    background-color: rgba($color_primary-dark, 0.8);
  }
}
