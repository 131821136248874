.dealer-map {
	$that: &;

	position: relative;
	height: 74.4vw;
	max-height: 646px;

	@include laptop {
		height: 100%;
		max-height: none;
	}

	&__image {
		@include absolute-fit(cover);
	}

	&__pin {
		position: absolute;
		top: 16px;
		left: 16px;
	}

	[class*="-inner-panes"] {
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			z-index: 999;
			width: 100%;
			height: 100%;
			background-color: #0f1d1b;
			mix-blend-mode: overlay;
		}
	}

	@include ymaps-filter {
		filter: saturate(0.3) brightness(0.9);
		transform: translate3d(0, 0, 0);
	}
}
