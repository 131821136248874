.discharge-section {
	$that: &;

	padding-top: 48px;
	color: $color_dark;
	background-color: $white;

	@include desktop {
		padding-top: 80px;
	}

	&__container {
		@extend %container;
	}

	&__head {
		overflow: hidden;
	}

	&__arrow {
		display: flex;
		justify-content: center;
	}

	&__title {
		margin-top: 32px;
		font-size: 20px;
		line-height: 1.6;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 24px;
		}

		span {
			color: $color_accent;
		}
	}

	&__body {
		margin-top: 32px;
	}

	&__picture {
		position: relative;
		overflow: hidden;
		height: 361px;
		margin-bottom: -197px;

		@include phone-s {
			margin-bottom: -227px;
		}

		@include tablet {
			height: 1041px;
			margin-bottom: -487px;
		}

		@include laptop {
			display: none;
		}
	}

	&__svg-picture {
		position: absolute;
		top: 0;
		left: 50%;
		max-width: none;
		margin-left: -171px;

		@include phone-s {
			width: 940px;
			margin-left: -149px;
		}

		@include tablet {
			margin-left: -286px;
		}
	}

	&__image {
		position: relative;
		top: 80px;
		display: block;
		margin-left: auto;
		margin-right: auto;

		@include tablet {
			width: auto;
			top: 235px;
		}

		@include laptop {
			width: 312px;
			top: 142px;
		}

		@include desktop {
			width: auto;
			top: 173px;
		}
	}

	&__animate {
		position: relative;
		display: none;
		overflow: hidden;
		height: 797px;
		margin-bottom: -486px;

		@include laptop {
			display: block;
			height: 632px;
			margin-bottom: -339px;
		}

		@include desktop {
			height: 797px;
			margin-bottom: -425px;
		}
	}

	&__svg-animate {
		position: absolute;
		top: 0;
		left: 50%;
		width: 1280px;
		height: auto;
		margin-left: -505px;

		@include desktop {
			width: auto;
			margin-left: -635px;
		}
	}
}
