.feedback {
	$that: &;

	padding-top: 64px;
	padding-bottom: 64px;
	background: $white;
	overflow: hidden;

	@include tablet {
		padding-top: 96px;
		padding-bottom: 96px;
	}

	@include laptop {
		padding-top: 128px;
		padding-bottom: 128px;
	}

	&__inner {
		@extend %container;
	}

	&__head {
	}

	&__title {
		color: $color_dark;
		text-align: center;
		transform-origin: center;
	}

	&__subtitle {
		color: $color_primary-dark;
		font-size: 14px;
		line-height: 1.714285714;
		font-weight: 500;
		text-align: center;
		transform-origin: center;

		@include tablet {
			font-size: 16px;
		}
	}

	&__body {
		margin-top: 40px;

		@include tablet {
			margin-top: 64px;
		}
	}

	&__fields-form {
		max-width: 632px;
		margin: 0 auto;
	}

	&__field-form {
		margin-top: 32px;

		&:first-child {
			margin-top: 0;
		}

		@include tablet {
			margin-top: 48px;
		}
	}

	&__action {
		display: flex;
		justify-content: center;
		margin-top: 32px;

		@include tablet {
			margin-top: 48px;
		}
	}
}
