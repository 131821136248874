*,
::before,
::after {
	box-sizing: border-box;
	// Fix some resize in flexbox elements
	// min-width: 0;
	// min-height: 0;
	background-repeat: no-repeat;
}

::before,
::after {
	text-decoration: inherit;
	vertical-align: inherit;
}

*,
*:active {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
	-webkit-tap-highlight-color: transparent !important;
}

*:focus {
	outline: none;
}

html {
	line-height: 1;
	tab-size: 4;
	word-break: break-word;
	cursor: default;
}

audio,
canvas,
iframe,
img,
svg,
video {
	vertical-align: middle;
}

svg:not([fill]) {
	fill: currentColor;
}

table {
	border-collapse: collapse;
}

textarea {
	resize: vertical;
}

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

iframe,
img,
input,
select,
textarea,
video {
	max-width: 100%;
	height: auto;
}

pre,
code,
kbd,
samp {
	font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
		"Liberation Mono", "Courier New", monospace;
}

@media (prefers-reduced-motion: reduce) {
	*,
	::before,
	::after {
		animation-delay: -1ms !important;
		animation-duration: 1ms !important;
		animation-iteration-count: 1 !important;
		background-attachment: initial !important;
		scroll-behavior: auto !important;
		transition-delay: 0s !important;
		transition-duration: 0s !important;
	}
}

// OPTIONAL
blockquote,
dl,
dd,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre {
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-size: inherit;
	font-weight: inherit;
}

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

iframe {
	border: 0;
}

ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	color: inherit;
	text-decoration: inherit;
}

button,
input,
optgroup,
select,
textarea {
	padding: 0;
	line-height: inherit;
	color: inherit;
}

button {
	background: transparent;
	border: 0;
}

button,
[role="button"] {
	cursor: pointer;
}

.clusterIcon {
	width: 40px;
	height: 40px;
	position: relative;
	top: -20px;
	left: -20px;
	font-weight: 500;
	font-size: 20px;
	line-height: 1;
	background: $color_accent;
	border: 7px solid $white;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.13);
	color: $white;
	border-radius: 100%;

	display: flex;
	justify-content: center;
	align-items: center;
}

.placemarkIcon {
	width: 36px;
	height: 36px;
	position: relative;
	top: -18px;
	left: -18px;
	background-image: url("../images/map_pin.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.13);
	border-radius: 100%;
}

.placemarkIcon._is-active {
	width: 36px;
	height: 36px;
	position: relative;
	top: -18px;
	left: -18px;
	background-image: url("../images/map_pin_active.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.13);
	border-radius: 100%;
}

.placemarkIcon._is-general {
	width: 36px;
	height: 36px;
	position: relative;
	top: -18px;
	left: -18px;
	background-image: url("../images/map_pin_general.svg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.13);
	border-radius: 100%;
}
