.menu {
  $that: &;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;

  .menu_open & {
    opacity: 1;
    pointer-events: all;
  }

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color_dark, 0.64);
    opacity: 0;

    .menu_open & {
      opacity: 1;
      transition: opacity 0.6s 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .menu_closing & {
      opacity: 0;
      transition: opacity 0.6s 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    margin-right: 32px;

    @include tablet {
      margin-top: 50px;
      margin-right: 60px;
    }
  }

  &__main {
    position: relative;
    flex: none;
    width: 100%;
    max-width: 567px;
    height: 100%;
    background-color: #fff;
    transform: translate3d(100%, 0, 0);
    will-change: transform;

    .menu_open & {
      transform: translate3d(0, 0, 0);
      transition: transform 0.5s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    .menu_closing & {
      transform: translate3d(100%, 0, 0);
      transition: transform 0.5s 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 80px 40px 40px;
    color: $color_dark;
    overflow-y: auto;

    @include tablet {
      padding: 80px;
    }
  }

  &__list {
  }

  &__item {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    font-family: $font_display;
    font-size: 24px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;

    &:hover,
    &._is-active {
      color: $color_primary-dark;
    }

    &:hover {
      #{$that}__text-link span {
        transform: translateY(-100%) skewY(-4deg);

        &::after {
          transform: skewY(4deg);
        }
      }
    }
  }

  &__text-link {
    display: block;
    overflow: hidden;

    span {
      display: block;
      position: relative;
      transform-origin: left top;
      transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1);

      &::after {
        content: attr(data-text);
        display: block;
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: left top;
        transition: transform 0.9s cubic-bezier(0.19, 1, 0.22, 1);
      }
    }
  }

  &__info {
    margin-top: 40px;
    padding-top: 32px;
    padding-bottom: 48px;
    font-size: 16px;
    line-height: 1.625;
    font-weight: 500;
    border-top: 1px solid rgba(0, 0, 0, 0.07);

    @include tablet {
      display: flex;
    }
  }

  &__phone {
  }

  &__link-phone {
    &:hover {
      color: $color_primary-dark;
    }
  }

  &__email {
    @include tablet {
      margin-left: 32px;
    }
  }

  &__link-email {
    color: $color_secondary;

    &:hover {
      color: $color_primary-dark;
    }
  }

  &__actions {
    margin-top: auto;
  }

  &__item-actions {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }
  }

  &__button-actions {
    width: 100%;
    max-width: 250px;
    padding-left: 32px;
    padding-right: 32px;
  }
}
