html {
	min-width: 320px;
}

body {
	color: $color_body;
	font-family: $font_body;
	font-size: 14px;
	font-weight: 500;
	line-height: 1;
	background: $color_dark;
	// font-variant-numeric: lining-nums;
	-webkit-font-smoothing: antialiased;
	-webkit-overflow-scrolling: touch;
}

::selection {
	color: white;
	// Fix Chrome selection
	background: rgba($color_primary, 0.99);
}

::-moz-selection {
	color: white;
	background: $color_primary;
}

.will-change {
	will-change: transform, opacity;
}

.forMessages {
	margin-top: 48px;
	color: #ff5353;
	line-height: 1.6;
	text-align: center;
}
