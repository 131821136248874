.request-button {
	$that: &;

	display: flex;
	align-items: center;
	cursor: pointer;

	&__text {
		position: relative;
		display: none;
		margin-right: 16px;
		padding-bottom: 6px;
		color: $color_primary;
		font-size: 14px;
		line-height: 1.285714286;
		font-weight: 500;
		text-transform: uppercase;
		transition: color 0.35s cubic-bezier(0.5, 1, 0.89, 1);

		@include tablet {
			display: block;
		}

		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: rgba(255, 255, 255, 0.15);

			.section_light & {
				background-color: rgba($color_secondary, 0.15);
			}
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $color_accent;
			transform: scale(0);
			opacity: 0;
			transform-origin: center;
			transition: transform 0.35s 0.1s cubic-bezier(0.5, 1, 0.89, 1),
				opacity 0.35s 0.1s cubic-bezier(0.5, 1, 0.89, 1);
			will-change: transform, opacity;
		}

		#{$that}:hover & {
			color: $color_accent;

			&::after {
				transform: scale(1);
				opacity: 1;
			}
		}

		.section_light & {
			color: $color_dark;
		}
	}

	&__icon {
		transition: color 0.35s cubic-bezier(0.5, 1, 0.89, 1);

		.section_light & {
			color: $color_dark;
		}

		#{$that}:hover & {
			color: $color_accent;
		}
	}
}
