.characteristics-download {
  $that: &;

  &__link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 24px;
    min-height: 120px;
    border: 1px solid #26282a;
    transition: border-color 0.25s ease-in-out;

    @include tablet {
      min-height: 145px;
    }

    &:hover {
      border-color: $color_primary;
    }
  }

  &__icon {
  }

  &__svg-icon {
    * {
      transition: fill 0.3s ease-in-out;
    }

    #{$that}__link:hover & {
      * {
        fill: $color_primary;
      }
    }
  }

  &__text {
    position: relative;
    margin-top: 12px;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-align: center;
    transition: color 0.3s ease-in-out;

    #{$that}__link:hover & {
      color: $color_primary;
    }

    @include tablet {
      font-size: 16px;
    }

    span {
      position: relative;
      color: $color_primary;
    }
  }

  &__shadow {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 133px;
    height: 48px;
    display: none;
    justify-content: center;
    align-items: center;
    transform: translate(-50%, -50%);

    @include laptop {
      display: flex;
    }

    img {
      max-width: none;
    }
  }
}
