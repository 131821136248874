.dealer-list {
	$that: &;

	@include laptop {
		display: flex;
		flex-direction: column;
		height: 100%;
		padding-top: 64px;
	}

	&__head {
		padding-left: 30px;
		padding-right: 30px;

		@include phone-s {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include tablet {
			display: flex;
			align-items: center;
			padding-left: 40px;
			padding-right: 40px;
		}

		@include laptop {
			flex: none;
		}

		@include desktop {
			padding-left: 64px;
			padding-right: 64px;
		}
	}

	&__name {
		color: $color_primary;
		font-size: 24px;
		line-height: 1.25;
		font-weight: 500;
		text-transform: uppercase;
		text-align: center;

		._name_hidden & {
			display: none;
		}
	}

	&__search {
		margin-top: 32px;

		@include tablet {
			flex: 1 1 0%;
			margin-top: 0;
			margin-left: 20px;
		}

		._name_hidden & {
			margin-left: 0;
		}
	}

	&__body {
		position: relative;
		height: 480px;
		margin-top: 8px;
		overflow: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}

		@include laptop {
			flex: 1 1 0%;
			height: auto;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
				width: 100%;
				height: 95px;
				background: linear-gradient(
					180deg,
					#161719 0%,
					rgba(22, 23, 25, 0) 100%
				);
				pointer-events: none;
				transition: opacity 0.4s ease;
				opacity: 0;
			}

			&::after {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 95px;
				background: linear-gradient(
					180deg,
					rgba(22, 23, 25, 0) 0%,
					#161719 100%
				);
				pointer-events: none;
				transition: opacity 0.4s ease;
			}
		}

		&._scroll_scrolled::before {
			opacity: 1;
		}

		&._scroll_end::after {
			opacity: 0;
		}
	}

	&__block {
	}

	&__item {
		padding-left: 30px;
		padding-right: 30px;

		&._is-active,
		&:hover {
			background: #1b1c1e;
		}

		@include phone-s {
			padding-left: 20px;
			padding-right: 20px;
		}

		@include tablet {
			padding-left: 40px;
			padding-right: 40px;
		}

		@include desktop {
			padding-left: 64px;
			padding-right: 64px;
		}
	}

	&__block-item {
		padding-top: 28px;
		padding-bottom: 28px;
		border-bottom: 1px solid #242427;

		@include laptop {
			#{$that}__item:last-child & {
				border-bottom: none;
			}
		}
	}

	&__name-item {
		color: #f5fdfb;
		font-size: 16px;
		line-height: 1.625;
		font-weight: 500;

		#{$that}__item._is-active &,
		#{$that}__item:hover & {
			color: $color_accent;
		}
	}

	&__info-item {
		margin-top: 8px;
	}

	&__text-item {
		color: $color_secondary;
		font-size: 14px;
		line-height: 1.857142857;
		font-weight: 500;

		#{$that}__item._is-active &,
		#{$that}__item:hover & {
			color: $white;
		}
	}

	&__site-item {
		margin-top: 1px;
		font-size: 14px;
		line-height: 1.857142857;
		font-weight: 500;

		a {
			display: inline-block;
			color: #84888e;
			text-decoration: underline;
			text-underline-offset: 4px;
		}
	}
}
