.whom {
  $that: &;

  position: relative;
  z-index: 1;
  padding-top: 96px;

  @include tablet {
    padding-top: 96px;
  }

  &__start-mobile {
    display: flex;
    justify-content: center;
    margin-top: -152px;
    margin-bottom: 32px;

    @include tablet {
      margin-bottom: 112px;

      img {
        width: 566px;
        height: auto;
      }
    }

    @include laptop {
      display: none;
    }
  }

  &__start-desktop {
    display: none;
    justify-content: center;
    margin-top: -250px;
    margin-bottom: 96px;

    @include laptop {
      display: flex;
    }
  }

  &__back {
    position: absolute;
    top: 96px;
    left: 50%;
    width: 100%;
    height: 1220px;
    transform: translate(-50%, 0);
    mix-blend-mode: luminosity;
    opacity: 0.36;

    @include tablet {
      height: 1511px;
      top: 283px;
      opacity: 0.5;
    }

    @include laptop {
      top: 336px;
    }
  }

  &__image-back {
    @include absolute-fit(cover);
    @include lazy-loaded;
  }

  &__inner {
    @extend %container;
    position: relative;
  }

  &__title {
    text-align: center;
  }

  &__amount {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  &__wrap-amount {
    position: relative;
  }

  &__shadow-amount {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 232px;
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -116px;

    img {
      max-width: none;
    }
  }

  &__number-amount {
    color: $color_primary;
    font-family: $font_display;
    font-size: 92px;
    line-height: 1;
    text-transform: uppercase;

    @include tablet {
      font-size: 128px;
    }
  }

  &__caption-amount {
    color: $color_secondary;
    font-size: 12px;
    line-height: 1.25;
    font-weight: 500;
    letter-spacing: 0.48em;
    margin-top: -4px;
    margin-left: 0.48em;

    @include tablet {
      position: absolute;
      bottom: 16px;
      left: 100%;
      margin-top: 0;
      margin-left: 8px;
      font-size: 16px;
      letter-spacing: normal;
      white-space: nowrap;
    }
  }

  &__text {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      font-size: 16px;
    }
  }

  &__body {
    margin-top: 40px;

    @include tablet {
      margin-top: 64px;
    }
  }

  &__list {
    @include laptop {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    &:first-child {
      border-color: transparent;
    }

    @include tablet {
      width: 382px;
      min-height: 382px;
      margin: 32px auto;
      border: none;
    }
  }

  &__circle {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    @include tablet {
      display: block;
    }
  }
}
