.garbage-section {
  $that: &;

  padding-top: 80px;
  padding-bottom: 80px;

  @include tablet {
    padding-top: 276px;
    padding-bottom: 92px;
  }

  @include laptop {
    padding-top: 208px;
    padding-bottom: 188px;
  }

  @include desktop {
    padding-top: 256px;
  }

  &__container {
    @extend %container;
  }

  &__head {
    @include desktop {
      display: none;
    }
  }

  &__name {
    font-size: 14px;
    line-height: 2.285714286;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      font-size: 24px;
      line-height: 1.25;
    }
  }

  &__desc {
    max-width: calc(269 / 375 * 100vw);
    margin-top: 6px;
    margin-left: auto;
    margin-right: auto;
    color: $color_secondary;
    font-size: 14px;
    line-height: 1.714285714;
    font-weight: 500;
    text-align: center;

    @include tablet {
      margin-top: 14px;
      font-size: 16px;
      line-height: 1.625;
    }
  }

  &__body {
    margin-top: 40px;

    @include tablet {
      margin-top: 54px;
    }

    @include desktop {
      margin-top: 0;
    }
  }

  &__video {
    position: relative;
    max-width: 949px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid rgba(255, 255, 255, 0.12);

    &::before {
      content: '';
      display: block;
      padding-bottom: 56.25%;
    }
  }

  &__left-angle-video {
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      width: 6px;
      height: 6px;

      @include tablet {
        width: 16px;
        height: 16px;
      }
    }

    &::before {
      top: -1px;
      left: -1px;
      border-top: 1px solid $white;
      border-left: 1px solid $white;
    }

    &::after {
      bottom: -1px;
      left: -1px;
      border-bottom: 1px solid $white;
      border-left: 1px solid $white;
    }
  }

  &__right-angle-video {
    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      width: 6px;
      height: 6px;

      @include tablet {
        width: 16px;
        height: 16px;
      }
    }

    &::before {
      top: -1px;
      right: -1px;
      border-top: 1px solid $white;
      border-right: 1px solid $white;
    }

    &::after {
      bottom: -1px;
      right: -1px;
      border-bottom: 1px solid $white;
      border-right: 1px solid $white;
    }
  }

  &__block-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__circle {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: none;
    width: 298px;
    height: 298px;
    margin-left: -149px;
    margin-bottom: -149px;

    @include desktop {
      display: block;
    }
  }

  &__info {
    position: absolute;
    right: -128px;
    bottom: 70px;
    width: 316px;
    display: none;

    @include desktop {
      display: block;
    }

    @include desktop-l {
      right: -182px;
    }
  }

  &__arrow {
    position: absolute;
    top: -12px;
    right: 100%;
    width: 240px;
    margin-right: 24px;
    transform-origin: right;

    svg {
      width: 100%;
      height: auto;
    }

    @include desktop-l {
      top: -16px;
      width: 277px;
    }
  }

  &__name-info {
    font-size: 24px;
    line-height: 1.25;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__desc-info {
    margin-top: 12px;
    color: $color_secondary;
    font-size: 16px;
    line-height: 1.625;
    font-weight: 500;
  }
}
