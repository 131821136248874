.title {
  $that: &;

  font-family: $font_display;
  font-size: 42px;
  line-height: 1.285714286;
  text-transform: uppercase;

  @include tablet {
    font-size: 62px;
  }
}
