.dealer-search {
  $that: &;

  position: relative;

  &__input {
    @include placeholder($color_secondary);

    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 30px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 500;
    border: none;
    border-bottom: 1px solid #242427;
    background: transparent;
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    margin-top: -1px;
  }
}
