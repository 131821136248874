.app-button {
	$that: &;

	position: relative;
	display: inline-block;
	vertical-align: middle;
	padding: 22px 64px;
	color: $color_primary-dark;
	font-size: 14px;
	line-height: 1.333333333;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	cursor: pointer;
	transition: border-color 0.25s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
		background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

	&::before,
	&::after {
		content: "";
		position: absolute;
		width: 1px;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.15);
		transform: scaleY(0.3);
		transition: transform 0.25s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
			background-color 0.25s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	&::before {
		top: 0;
		left: 0;
		transform-origin: top left;
	}

	&::after {
		bottom: 0;
		right: 0;
		transform-origin: bottom right;
	}

	&:hover {
		background-color: rgba(8, 173, 130, 0.07);
		border-color: $color_primary-dark;
		transition: border-color 0.25s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
			background-color 0.25s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

		&::before,
		&::after {
			transform: scaleY(1);
			background-color: $color_primary-dark;
			transition: transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1),
				background-color 0.25s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}

	&._secondary {
		background: rgba($color_primary-dark, 0.08);
		border: 1px solid $color_primary-dark;
		transition: color 0.25s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
			background-color 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);

		&::before,
		&::after {
			display: none;
			transform: none;
		}

		&:hover {
			color: $white;
			background: $color_primary-dark;
			transition: color 0.25s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1),
				background-color 0.25s 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		}
	}

	&._filled {
		color: $white;
		background: $color_primary-dark;
	}
}
