.body-section {
  $that: &;

  padding-bottom: 48px;

  @include laptop {
    overflow: hidden;
  }

  &__container {
    @extend %container;

    position: relative;
    z-index: 2;
  }

  &__back {
    color: #1f2124;
    font-family: $font_display;
    font-size: 200px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      font-size: calc(508 / 1024 * 100vw);
    }

    @include laptop {
      font-size: 508px;
    }
  }

  &__head {
    position: relative;
    z-index: 1;
    margin-top: -144px;

    @include tablet {
      margin-top: calc(-270 / 1024 * 100vw);
    }

    @include laptop {
      margin-top: -304px;
      margin-bottom: 128px;
    }
  }

  &__title {
    text-align: center;
  }

  &__subtitle {
    position: relative;
    width: 260px;
    margin-top: 8px;
    margin-left: auto;
    margin-right: auto;
    color: $color_primary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.857142857;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      margin-top: 0;
      width: 100%;
      font-size: 16px;
    }
  }

  &__shadow {
    position: absolute;
    bottom: -16px;
    z-index: 1;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 232px;
    height: 52px;
    margin-left: -116px;
  }

  &__img-shadow {
    max-width: none;
  }

  &__list {
    position: relative;
    z-index: 1;

    @include tablet {
      margin-top: 96px;
    }

    @include laptop {
      display: none;
    }
  }

  &__item {
    padding: 50px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    @include tablet {
      padding: 0;
      border: none;
    }
  }

  &__sequence {
    z-index: 1;
  }

  &__wrap-sequence {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &__inner-sequence {
    @extend %container;

    position: relative;
    height: 100%;
  }

  &__item-sequence {
    position: absolute;
    bottom: 50%;
    width: 344px;
    font-size: 24px;
    line-height: 1.333333333;
    font-weight: 500;
    text-align: right;

    &::before {
      content: "";
      position: absolute;
      top: 16px;
      left: 100%;
      width: 16px;
      height: 3px;
      margin-left: 16px;
      background-color: $color_primary;
    }

    &:nth-child(2n) {
      bottom: 0;
      top: 50%;
      right: 0;
      text-align: left;

      &::before {
        left: auto;
        right: 100%;
        margin-right: 16px;
      }
    }
  }

  &__guarantee {
    margin-top: 48px;

    @include tablet {
      margin-top: 128px;
    }
  }
}

.body-item {
  $that: &;

  @include tablet {
    display: flex;
    align-items: center;
  }

  &__picture {
    position: relative;
    height: 260px;

    @include tablet {
      flex: 1 1 0%;
      height: 480px;
      padding-right: 20px;

      .body-section__item:first-child & {
        order: 2;
        padding-right: 0;
        padding-left: 20px;
      }
    }
  }

  &__image-picture {
    @include absolute-fit(scale-down);
    @include lazy-loaded;
    border-color: transparent;
  }

  &__info {
    margin-top: 24px;

    @include tablet {
      flex: 1 1 0%;
      padding-left: calc(52 / 1024 * 100vw);

      .body-section__item:first-child & {
        padding-left: 0;
        padding-right: calc(52 / 1024 * 100vw);
      }
    }
  }

  &__name {
    font-size: 14px;
    line-height: 2.285714286;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      position: relative;
      font-size: 24px;
      line-height: 1.333333333;
      text-align: left;

      &::before {
        content: "";
        position: absolute;
        top: 16px;
        right: 100%;
        width: 16px;
        height: 3px;
        margin-right: 16px;
        background-color: $color_primary;
      }

      .body-section__item:first-child & {
        text-align: right;

        &::before {
          content: "";
          position: absolute;
          top: 16px;
          left: 100%;
          width: 16px;
          height: 3px;
          margin-left: 16px;
          background-color: $color_primary;
        }
      }
    }
  }

  &__desc {
    max-width: 253px;
    margin: 0 auto;
    padding-top: 8px;
    color: $color_secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.714285714;
    text-align: center;

    @include tablet {
      max-width: 315px;
      margin: 0;
      padding-top: 12px;
      font-size: 16px;
      line-height: 1.5;
      text-align: left;

      .body-section__item:first-child & {
        margin-left: auto;
        text-align: right;
      }
    }
  }
}
