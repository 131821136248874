// MEDIA (Mobile First)
@mixin phone-s {
  @media (max-width: 374.98px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin desktop-l {
  @media (min-width: 1440px) {
    @content;
  }
}

// MEDIA (Desktop First)
// @mixin phone-s {
//   @media (max-width: 374.98px) {
//     @content;
//   }
// }

// @mixin phone {
//   @media (max-width: 639.98px) {
//     @content;
//   }
// }

// @mixin tablet {
//   @media (max-width: 1023.98px) {
//     @content;
//   }
// }

// @mixin laptop {
//   @media (max-width: 1279.98px) {
//     @content;
//   }
// }

// @mixin desktop {
//   @media (max-width: 1535.98px) {
//     @content;
//   }
// }

// OBJECT FIT

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null) {
  -o-object-fit: $fit;
  object-fit: $fit;
  @if $position {
    -o-object-position: $position;
    object-position: $position;
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

// ABSOLUTE FIT

@mixin absolute-fit($fit: fill, $position: null) {
  @include object-fit($fit, $position);

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// PLACEHOLDER COLOR

@mixin placeholder($color: #000) {
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

// VISUALLY HIDDEN

@mixin visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
}

// FULL WIDTH CONTAINER

@mixin full-width {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

// LAZY LOADED

@mixin lazy-loaded {
  opacity: 0;
  transition: opacity 0.15s ease;

  &._is-loaded {
    opacity: 1;
  }
}

// YANDEX MAP FILTER

@mixin ymaps-filter {
  [class*='-ground-pane'] {
    position: relative;
    @content;
  }
}
