.load-screen {
	$that: &;

	position: fixed;
	top: 0;
	left: 0;
	display: none;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	z-index: 9999999;
	background: #17181a;

	@include laptop {
		display: flex;
	}

	.page_detail & {
		display: none;
	}

	&__back {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__img-back {
		@include absolute-fit(cover);

		opacity: 0;

		&._is-loaded {
			opacity: 1;
			transition: opacity 0.4s ease-in-out;
		}
	}

	&__container {
		@extend %container;
	}

	&__text {
		position: relative;
		color: $white;
		font-family: $font_display;
		font-size: 55px;
		text-align: center;
		letter-spacing: 32px;
		opacity: 0.05;
		transition: opacity 1.2s 0.6s cubic-bezier(0.19, 1, 0.22, 1),
			letter-spacing 1.2s 0.4s cubic-bezier(0.19, 1, 0.22, 1);

		@include laptop {
			font-size: 139px;
		}

		.page_loaded & {
			opacity: 1;
			letter-spacing: 0;
		}
	}

	&__letter-text {
		color: $white;

		.page_loaded & {
			color: $color_primary;
			transition: color 1.6s 1s cubic-bezier(0.19, 1, 0.22, 1);

			&:nth-child(1) {
				transition-delay: 1s;
			}

			&:nth-child(2) {
				transition-delay: 1.1s;
			}

			&:nth-child(3) {
				transition-delay: 1.2s;
			}

			&:nth-child(4) {
				transition-delay: 1.3s;
			}

			&:nth-child(5) {
				transition-delay: 1.4s;
			}

			&:nth-child(6) {
				transition-delay: 1.5s;
			}
		}
	}
}
