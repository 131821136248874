.intro-section {
  $that: &;

  position: relative;
  z-index: 1;
  margin-bottom: 96px;

  @include laptop {
    margin-bottom: -100vh;
  }

  &__container {
  }

  &__head {
    position: relative;
    z-index: 1;
    padding-top: calc(300 / 1080 * 100vh);
  }

  &__title {
    font-family: $font_display;
    font-size: 55px;
    text-align: center;

    @include laptop {
      font-size: 139px;
    }

    span {
      display: block;
      color: $color_primary;

      @include tablet {
        display: inline;
      }
    }
  }

  &__content {
    position: relative;
    will-change: transform;
    margin-top: 24px;

    @include laptop {
      margin-top: 0;
    }
  }

  &__wrap-content {
    position: relative;
    height: auto;

    @include laptop {
      height: 100vh;
    }
  }

  &__description {
    @include laptop {
      position: absolute;
      top: 50%;
      right: 40px;
      z-index: 1;
    }

    @media (min-width: 1600px) {
      right: auto;
      left: 50%;
      transform: translate3d(100%, -100%, 0);
      margin-left: 64px;
    }
  }

  &__text-description {
    color: $color_primary;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    text-transform: uppercase;
    text-align: center;

    @include laptop {
      color: $white;
      font-size: 24px;
      text-align: left;
    }
  }

  &__sequence {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    margin-top: 64px;

    @include laptop {
      height: 100vh;
      margin-top: 0;
    }
  }

  &__canvas {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
    @include object-fit(scale-down);

    @include laptop {
      height: 100vh;

      transform: translate3d(0, -16px, 0);
      opacity: 0;
    }
  }

  &__light-wrap {
    @include laptop {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &__light {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &__left-light,
  &__right-light {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 103px;
    opacity: 0.8;

    img {
      width: calc(739px / 2);
    }

    @include tablet {
      width: 111px;
      height: 220px;
      opacity: 1;

      img {
        width: auto;
      }
    }
  }

  &__left-light {
    position: absolute;
    top: 50%;
    right: 50%;
    margin-top: -64px;
    margin-right: 80px;

    @include tablet {
      margin-top: -160px;
      margin-right: 192px;
    }
  }

  &__right-light {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -32px;
    margin-left: 80px;

    @include tablet {
      margin-top: -64px;
      margin-left: 192px;
    }
  }

  &__img-light {
    max-width: none;
  }

  &__circle-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    margin-top: -150px;
    margin-left: -150px;

    @include tablet {
      width: 636px;
      height: 636px;
      margin-top: -318px;
      margin-left: -318px;
    }
  }

  &__circle {
    width: 100%;
    height: 100%;

    @include laptop {
      transform: rotate(-45deg);
      opacity: 0;
    }
  }
}
