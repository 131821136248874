.body-purification {
	$that: &;

	position: relative;

	@include tablet {
		height: calc(605 / 1024 * 100vw);
	}

	&__picture {
		position: relative;
		height: 318px;

		@include tablet {
			position: absolute;
			top: 0;
			left: -144px;
			width: 100%;
			height: 100%;
		}
	}

	&__image {
		@include absolute-fit(cover);
		@include lazy-loaded;

		@include tablet {
			@include absolute-fit(contain, left);
		}
	}

	&__info {
		margin-top: 12px;

		@include tablet {
			position: relative;
			display: flex;
			align-items: center;
			width: 50%;
			height: 100%;
			margin-left: 50%;
		}
	}

	&__container {
		@extend %container;

		@include tablet {
			max-width: 233px;
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__title {
		color: $color_primary;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;

		@include tablet {
			font-size: 16px;
		}
	}

	&__percent {
		margin-top: 16px;
		font-family: $font_display;
		font-size: 74px;
		font-weight: 700;
		text-align: center;

		@include tablet {
			font-size: 82px;
		}

		span {
			font-size: 24px;
		}
	}

	&__text {
		max-width: calc(233 / 375 * 100vw);
		margin: 0 auto;
		padding-top: 8px;
		font-size: 14px;
		line-height: 1.857142857;
		text-transform: uppercase;
		text-align: center;

		@include tablet {
			margin-top: calc(80 / 1024 * 100vw);
			font-size: 16px;
		}
	}
}
