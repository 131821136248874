.size-section {
  $that: &;

  padding-top: 32px;
  padding-bottom: 85px;
  transform: translate3d(0, 0, 0);

  &__container {
    @extend %container;
  }

  &__columns {
    @include laptop {
      display: flex;
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  &__column {
    @include laptop {
      position: relative;
      flex: 1 1 0%;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  &__head {
    @include laptop {
      max-width: 33.333333333%;
      min-width: 420px;
    }
  }

  &__block-head {
    text-align: center;

    @include laptop {
      position: absolute;
      bottom: 50%;
      width: 100%;
      max-width: 420px;
      margin: 0 auto;
      text-align: right;
    }
  }

  &__title {
    //text-align: center;

    span {
      color: $color_primary;
    }
  }

  &__subtitle {
    margin-top: 2px;
    color: $color_primary;
    font-size: 14px;
    line-height: 2;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      font-size: 24px;
      line-height: 1.583333333;
    }

    @include laptop {
      //display: none;
      margin-top: 6px;
      text-align: right;
    }
  }

  &__graph {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    padding-left: 8.333333333%;

    @include laptop {
      justify-content: flex-start;
    }
  }

  &__picture-graph {
    position: relative;
    //
    //&::before,
    //&::after {
    //  content: "";
    //  position: absolute;
    //  z-index: 1;
    //  width: 64px;
    //  height: 126px;
    //  background: rgba(17, 204, 155, 0.3);
    //  mix-blend-mode: screen;
    //  border-radius: 100%;
    //  filter: blur(80px);
    //}
    //
    //&::before {
    //  top: 64px;
    //  left: 0;
    //
    //  @include laptop {
    //    top: 20%;
    //    left: -48px;
    //  }
    //}
    //
    //&::after {
    //  right: 16px;
    //  bottom: 64px;
    //
    //  @include laptop {
    //    bottom: 30%;
    //  }
    //}
  }

  &__shadow-left,
  &__shadow-right {
    position: absolute;
    z-index: 1;
    width: 64px;
    height: 126px;
    display: none;
    justify-content: center;
    align-items: center;

    @include laptop {
      display: flex;
    }

    img {
      max-width: none;
    }
  }

  &__shadow-left {
    top: 64px;
    left: 0;

    @include laptop {
      top: 20%;
      left: -48px;
    }
  }

  &__shadow-right {
    right: 16px;
    bottom: 64px;

    @include laptop {
      bottom: 30%;
    }
  }

  &__image-graph {
  }

  &__characteristics {
    margin-top: 40px;

    @include tablet {
      margin-top: 80px;
    }

    @include laptop {
      padding-top: 104px;
    }
  }
}
