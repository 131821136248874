.footer {
	$that: &;

	position: relative;
	background: $color_dark;

	@include tablet {
		&::before {
			content: "";
			position: absolute;
			top: -1px;
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 70px 54px 0 0;
			border-color: $white transparent transparent transparent;

			.page_detail & {
				display: none;
			}
		}

		&::after {
			content: "";
			position: absolute;
			top: -1px;
			right: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 54px 70px 0;
			border-color: transparent $white transparent transparent;

			.page_detail & {
				display: none;
			}
		}

		.page_detail & {
			border-top: 1px solid rgba(255, 255, 255, 0.08);
		}
	}

	&__inner {
		@extend %container;
	}

	&__top {
		padding-top: 60px;
		padding-bottom: 60px;

		@include tablet {
			padding-top: 80px;
			padding-bottom: 80px;
		}
	}

	&__info {
		font-size: 16px;
		line-height: 1.625;
		text-align: center;
	}

	&__phone {
		color: $color_primary;
	}

	&__mail {
		color: $color_secondary;
	}

	&__dealer {
		display: flex;
		justify-content: center;
		margin-top: 32px;

		@include tablet {
			margin-top: 48px;
		}
	}

	&__logo {
		display: flex;
		justify-content: center;
		transform-origin: center;
		backface-visibility: hidden;
	}

	&__link-logo {
		position: relative;
		display: inline-block;
		vertical-align: top;

		@include laptop {
			&::before {
				content: "";
				position: absolute;
				left: -24px;
				top: -24px;
				right: -24px;
				bottom: -24px;
			}
		}
	}

	&__svg-logo {
		width: 175px;
		height: auto;
	}

	&__main {
		@extend %container;

		color: $color_secondary;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.714285714;
	}

	&__columns-main {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		@include laptop {
			flex-direction: row;
			align-items: flex-start;
		}
	}

	&__column-main {
		flex: 1 1 0%;
		max-width: 340px;
		padding-left: calc(64 / 1280 * 100vw);
		padding-right: calc(64 / 1280 * 100vw);
		padding-bottom: 16px;

		@include desktop {
			padding-left: 64px;
			padding-right: 64px;
		}
	}

	&__center-main {
		padding-top: 48px;
		padding-bottom: 48px;

		@include laptop {
			padding-top: 0;
			padding-bottom: 16px;
			border-left: 1px solid #26282a;
			border-right: 1px solid #26282a;
		}
	}

	&__links {
		text-align: center;

		@include laptop {
			text-align: right;
		}
	}

	&__item-links {
		margin-top: 16px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__ref-links {
		border-bottom: 1px solid currentColor;

		&:hover {
			color: $color_primary;
		}
	}

	&__address {
		margin-top: 32px;
		text-align: center;
	}

	&__map {
		margin-top: 16px;
		text-align: center;
	}

	&__link-map {
		text-transform: lowercase;
		border-bottom: 1px solid currentColor;

		&:hover {
			color: $color_primary;
		}
	}

	&__schedule {
		text-align: center;

		@include laptop {
			text-align: left;
		}
	}

	&__row-schedule {
		margin-top: 10px;

		&:first-child {
			margin-top: 0;
		}
	}

	&__bottom {
		margin-top: 32px;
		padding-top: 32px;
		padding-bottom: 32px;
		border-top: 1px solid #26282a;
	}

	&__inner-bottom {
		@extend %container;

		display: flex;
		flex-direction: column;
		align-items: center;
		max-width: none;
		color: $color_secondary;
		font-size: 16px;
		line-height: 1.625;
		text-align: center;

		@include laptop {
			flex-direction: row;
			justify-content: space-between;
			text-align: left;
		}
	}

	&__copy {
	}

	&__dev {
		margin-top: 8px;

		@include laptop {
			margin-top: 0;
			margin-left: 38px;
			white-space: normal;
			white-space: nowrap;
			align-self: flex-start;
		}
	}

	&__link-dev {
		position: relative;
		display: inline-block;
		vertical-align: top;
		margin-left: 4px;
		text-transform: uppercase;
		overflow: hidden;
		transition: color 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);

		&::before,
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
		}

		&::before {
			background: $color_secondary;
		}

		&::after {
			background: $color_primary;
			transform: scale(0);
			transform-origin: right center;
			transition: transform 0.6s cubic-bezier(0.22, 0.61, 0.36, 1);
		}

		&:hover {
			color: $color_primary;

			&::after {
				transform-origin: left center;
				transform: scale(1);
			}
		}
	}
}
