.characteristics {
  $that: &;

  padding: 30px;
  border-top: 11px solid rgba(255, 255, 255, 0.06);
  background: linear-gradient(180deg, #1f2023 0%, #161719 100%);
  overflow: hidden;

  @include tablet {
    padding: 51px 60px 60px;
  }

  @include laptop {
    padding-top: 80px;
    padding-left: 96px;
    padding-right: 96px;
  }

  &__columns {
    margin-top: -64px;

    @include laptop {
      display: flex;
    }
  }

  &__column {
    padding-top: 64px;

    @include laptop {
      flex: 1 1 0%;
    }
  }

  &__info {
    @include laptop {
      padding-right: 96px;
      border-right: 1px solid rgba(255, 255, 255, 0.04);
    }
  }

  &__title {
    color: $color_primary;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-transform: uppercase;

    @include tablet {
      font-size: 24px;
    }
  }

  &__list {
    @include laptop {
      margin-top: 48px;
      padding-bottom: 32px;
    }
  }

  &__item {
    margin-top: 24px;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;

    @include tablet {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-left: -16px;
      font-size: 16px;
    }
  }

  &__term {
    color: $color_secondary;

    @include tablet {
      padding-left: 16px;
    }
  }

  &__detail {
    margin-top: 2px;
    color: $white;

    @include tablet {
      margin-top: 0;
      padding-left: 16px;
    }
  }

  &__downloads {
    margin-left: -30px;
    margin-right: -30px;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      margin-top: -32px;
      margin-left: -32px;
      margin-right: 0;
    }

    @include laptop {
      display: block;
      margin-top: -42px;
      margin-left: 0;
      padding-left: 96px;
    }
  }

  &__item-downloads {
    margin-top: 24px;

    &:first-child {
      margin-top: 0;
    }

    @include tablet {
      flex: none;
      width: 50%;
      margin-top: 0;
      padding-top: 32px;
      padding-left: 32px;
    }

    @include laptop {
      width: auto;
      max-width: 460px;
      margin-left: auto;
      margin-right: auto;
      padding-top: 42px;
      padding-left: 0;
    }
  }

  &__price {
    margin-top: 64px;
    margin-left: -30px;
    margin-right: -30px;

    #{$that}__title {
      padding-left: 30px;
      padding-right: 30px;
    }

    .characteristics-price {
      margin-top: 32px;
    }

    @include tablet {
      margin-left: 0;
      margin-right: 0;

      #{$that}__title {
        padding-left: 0;
        padding-right: 0;
      }

      .characteristics-price {
        margin-top: 40px;
      }
    }
  }
}
