.form-input {
  $that: &;

  position: relative;

  &__field {
    width: 100%;
    padding: 0 0 7px 0;
    color: $color_dark;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    border: 0 none;

    @include tablet {
      font-size: 16px;
    }
  }

  &__line {
    position: relative;
    width: 100%;
    height: 8px;

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(50, 50, 56, 0.21);
      background: -moz-linear-gradient(
        left,
        rgba(30, 87, 153, 0) 0%,
        rgba(50, 50, 56, 0.21) 50%,
        rgba(36, 36, 39, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(30, 87, 153, 0) 0%,
        rgba(50, 50, 56, 0.21) 50%,
        rgba(36, 36, 39, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(30, 87, 153, 0) 0%,
        rgba(50, 50, 56, 0.21) 50%,
        rgba(36, 36, 39, 0) 100%
      );
    }

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 1px;
      background-color: $color_primary-dark;
      background: -moz-linear-gradient(
        left,
        rgba(8, 173, 130, 0) 0%,
        rgba(8, 173, 130, 1) 50%,
        rgba(8, 173, 130, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(8, 173, 130, 0) 0%,
        rgba(8, 173, 130, 1) 50%,
        rgba(8, 173, 130, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(8, 173, 130, 0) 0%,
        rgba(8, 173, 130, 1) 50%,
        rgba(8, 173, 130, 0) 100%
      );
      transform: scaleX(0);
      transform-origin: center;
      transition: transform 0.35s cubic-bezier(0.5, 1, 0.89, 1);
      will-change: transform;

      #{$that}._is-focus &,
      #{$that}._is-filled & {
        transform: none;
      }
    }
  }

  &__label {
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    color: $color_secondary;
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    transition: transform 0.35s cubic-bezier(0.5, 1, 0.89, 1),
      color 0.35s cubic-bezier(0.5, 1, 0.89, 1);
    transform-origin: center top;
    will-change: transform;

    @include tablet {
      font-size: 16px;
    }

    #{$that}._is-focus &,
    #{$that}._is-filled & {
      color: $color_primary-dark;
      transform: scale(0.75) translateY(-16px);
    }

    span {
      position: relative;
      top: -4px;
      color: $color_accent;
    }
  }

  ._error_field {
    display: block;
    width: 100%;
    margin-top: 6px;
    color: #fd9c9c;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
  }

  &._error_field {
    #{$that}__field {
      color: #ff5353;
    }

    #{$that}__label {
      color: #fd9c9c;
    }

    #{$that}__line {
      &::after,
      &::before {
        background-color: #ff4f4f;
        background: -moz-linear-gradient(
          left,
          rgba(255, 79, 79, 0) 0%,
          rgba(255, 79, 79, 1) 50%,
          rgba(255, 79, 79, 0) 100%
        );
        background: -webkit-linear-gradient(
          left,
          rgba(255, 79, 79, 0) 0%,
          rgba(255, 79, 79, 1) 50%,
          rgba(255, 79, 79, 0) 100%
        );
        background: linear-gradient(
          to right,
          rgba(255, 79, 79, 0) 0%,
          rgba(255, 79, 79, 1) 50%,
          rgba(255, 79, 79, 0) 100%
        );
      }
    }
  }
}
