@font-face {
  font-family: 'Cera Pro';
  src: url('../fonts/cerapro-medium.woff2') format('woff2'),
    url('../fonts/cerapro-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas';
  src: url('../fonts/bebasneue_bold.woff2') format('woff2'),
    url('../fonts/bebasneue_bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
