.form-file {
  $that: &;

  &__input {
    @include visually-hidden;
  }

  &__label {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 316px;
    margin: 0 auto;
    padding: 32px 48px;
    border: 1px solid #EDEDED;
    border-radius: 4px;
    transition: border-color .45s ease-in-out;
    cursor: pointer;

    &:hover,
    #{$that}._is-attached & {
      border-color: $color_primary-dark;
    }
  }

  &__icon {

  }

  &__text {
    margin-top: 8px;
    color: $color_secondary;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;

    #{$that}._is-attached & {
      color: $color_dark;
    }
  }
}
