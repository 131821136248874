.body-guarantee {
  $that: &;

  position: relative;

  &__back {
    position: absolute;
    top: -600px;
    left: 0;
    display: none;
    width: 100%;
    height: 1627px;

    @include tablet {
      display: block;
    }
  }

  &__image-back {
    @include absolute-fit(cover);
    @include lazy-loaded;
  }

  &__container {
    @extend %container;
    position: relative;
  }

  &__head {
  }

  &__title {
    font-size: 14px;
    line-height: 1.714285714;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @include tablet {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  &__shield {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 635px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;

    @include tablet {
      margin-top: 120px;
    }
  }

  &__svg-shield-tablet {
    display: block;
    width: 162px;
    height: auto;
    margin-top: 16px;
    transform-origin: top center;

    @include tablet {
      width: auto;
      margin-top: 0;
    }
  }

  &__stem {
    position: absolute;
    top: -12px;
    left: 50%;
    width: auto;
    height: 48px;
    margin-left: -2px;

    @include tablet {
      top: -48px;
      height: auto;
    }
  }

  &__wing-left {
    position: absolute;
    top: 16px;
    left: 24px;
    width: 44px;
    //display: none;

    @include tablet {
      top: 40px;
      left: 0;
      display: block;
      width: auto;
      transform-origin: top right;
    }
  }

  &__wing-right {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 44px;
    //display: none;

    @include tablet {
      top: 40px;
      right: 0;
      display: block;
      width: auto;
      transform-origin: top left;
    }
  }

  &__icon {
    position: absolute;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    width: 64px;
    height: auto;
    text-align: center;

    @include tablet {
      top: 80px;
      width: auto;
    }
  }

  &__lines {
    display: none;
    margin-top: -112px;

    @include tablet {
      display: block;
    }
  }

  &__arrow {
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }

  &__svg-arrow {
  }

  &__body {
    @include tablet {
      margin-top: 160px;
    }

    @include laptop {
      display: none;
    }
  }

  &__list {
  }

  &__item {
    padding: 50px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);

    @include tablet {
      margin-top: 54px;
      padding: 0;
      border: none;
    }

    &:last-child {
      padding-top: 32px;

      @include tablet {
        padding-top: 0;
      }
    }
  }

  &__block-item {
    @include tablet {
      position: relative;
      display: flex;
    }
  }

  &__picture-item {
    position: relative;
    height: 260px;

    @include tablet {
      flex: 1 1 0%;
    }

    @include tablet {
      height: 500px;
      order: 2;
      margin-left: 40px;
    }
  }

  &__image-item {
    @include absolute-fit(scale-down);
    @include lazy-loaded;
  }

  &__info-item {
    margin-top: 24px;

    @include tablet {
      flex: 1 1 0%;
      margin-top: 80px;
    }
  }

  &__name-item {
    position: relative;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @include tablet {
      max-width: 359px;
      margin-left: auto;
      text-align: right;
      font-size: 24px;
      line-height: 1.333333333;

      &::before {
        content: "";
        position: absolute;
        top: 15px;
        left: 100%;
        width: 16px;
        height: 3px;
        margin-left: 16px;
        background-color: $color_primary;
      }
    }
  }

  &__desc-item {
    margin: 0 auto;
    padding-top: 8px;
    color: $color_secondary;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.714285714;
    text-align: center;

    @include tablet {
      padding-top: 12px;
      font-size: 16px;
      line-height: 1.625;
      text-align: right;
    }
  }

  &__purification {
    margin-top: 24px;

    @include laptop {
      display: none;
    }
  }

  &__sequence {
    position: relative;
    display: none;
    width: 100%;
    height: 100vh;
    margin-top: 160px;

    @include laptop {
      display: block;
    }
  }

  &__canvas {
    display: block;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    box-sizing: content-box;

    @include object-fit(contain);
  }

  &__wrap-sequence {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }

  &__inner-sequence {
    @extend %container;

    position: relative;
    height: 100%;
  }

  &__list-sequence {
  }

  &__item-sequence {
    position: absolute;
    width: 358px;

    &._top-left {
      bottom: 50%;
      left: 0;
    }

    &._bottom-right {
      top: 50%;
      right: 0;
    }
  }

  &__name-sequence {
    font-size: 24px;
    line-height: 1.333333333;
    font-weight: 500;
    text-transform: uppercase;

    &::before {
      content: "";
      position: absolute;
      top: 16px;
      width: 16px;
      height: 3px;
      background-color: $color_primary;
    }

    #{$that}__item-sequence._top-left & {
      text-align: right;

      &::before {
        left: 100%;
        margin-left: 16px;
      }
    }

    #{$that}__item-sequence._bottom-right & {
      text-align: left;

      &::before {
        right: 100%;
        margin-right: 16px;
      }
    }
  }

  &__text-sequence {
    margin-top: 12px;
    color: $color_secondary;
    font-size: 16px;
    line-height: 1.625;
    font-weight: 500;
  }
}
