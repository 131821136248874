.service-section {
  $that: &;

  @include tablet {
    padding-bottom: 150px;
  }

  &__container {
    @extend %container;
  }

  &__head {
    position: relative;
    z-index: 1;

    @include tablet {
      padding-top: 135px;
      padding-bottom: 185px;
    }
  }

  &__circle {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    width: 637px;
    height: 636px;
    margin-left: -318px;

    @include tablet {
      display: block;
    }
  }

  &__title {
    text-align: center;
  }

  &__desc {
    margin-top: 20px;
    color: $color_secondary;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-align: center;

    span {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
      color: $color_primary;
      font-family: $font_display;
      font-size: 74px;
      line-height: 1;
      font-weight: 700;
    }

    @include tablet {
      margin-top: 30px;
      font-size: 24px;
      line-height: 1.25;

      span {
        font-size: 128px;
      }
    }
  }

  &__subtitle {
    margin-top: 10px;
    color: $color_secondary;
    font-size: 14px;
    line-height: 1.857142857;
    font-weight: 500;
    text-align: center;

    @include tablet {
      margin-top: 32px;
      font-size: 16px;
    }
  }

  &__body {
    margin-top: 24px;

    @include tablet {
      margin-top: 40px;
    }

    @include laptop {
      display: none;
    }
  }

  &__picture {
    @include out-gap;

    position: relative;
    height: 370px;

    @include tablet {
      height: calc(802 / 1024 * 100vw);
      max-height: 802px;
    }
  }

  &__image {
    @include absolute-fit(cover);
    @include lazy-loaded;
  }

  &__arrow {
    display: none;
    position: absolute;
    top: 60%;
    right: 60%;
    width: calc(260 / 1024 * 100vw);
    max-width: 260px;
    height: auto;

    @include tablet {
      display: block;
    }
  }

  &__sequence {
  }

  &__canvas#sequence-service {
    @include object-fit(cover);
  }

  &__lines-sequence {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      margin-right: 2%;
      width: 597px;
      height: 1px;
      background: #313135;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 493px;
      height: 1px;
      margin-left: 20%;
      background: #313135;
    }
  }

  &__arrow-sequence {
    position: absolute;
    right: 60%;
    bottom: 32px;
    width: calc(492 / 1920 * 100vw);
    max-width: 492px;
    height: auto;
  }
}
